import { FormControl, MenuItem, Pagination, Select, SelectChangeEvent, Stack } from '@mui/material';
import { ChangeEvent, useState, useEffect, useRef } from 'react';

interface MyPaginationProps {
  total: number; // Общее количество элементов
  initialPage?: number; // Начальная страница (опционально)
  initialLimit?: number; // Начальный лимит (опционально)
  delay?: number; // Задержка в миллисекундах (опционально)
  onPageChange: (page: number) => void; // Колбэк для изменения страницы
  onLimitChange: (limit: number) => void; // Колбэк для изменения лимита
}

const MyPagination: React.FC<MyPaginationProps> = ({
  total,
  initialPage = 1,
  initialLimit = 10,
  delay = 0, // По умолчанию без задержки
  onPageChange,
  onLimitChange,
}) => {
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);

  const pageChangeTimeout = useRef<NodeJS.Timeout | null>(null);
  const limitChangeTimeout = useRef<NodeJS.Timeout | null>(null);

  // Синхронизация `page` при изменении `initialPage`
  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  const handlePageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);

    if (pageChangeTimeout.current) {
      clearTimeout(pageChangeTimeout.current);
    }

    pageChangeTimeout.current = setTimeout(() => {
      onPageChange(value); // Вызываем колбэк после задержки
    }, delay);
  };

  const handleLimitChange = (event: SelectChangeEvent) => {
    const newLimit = +event.target.value;
    setLimit(newLimit);
    setPage(1); // Сбрасываем страницу на первую

    if (limitChangeTimeout.current) {
      clearTimeout(limitChangeTimeout.current);
    }

    limitChangeTimeout.current = setTimeout(() => {
      onLimitChange(newLimit); // Вызываем колбэк после задержки
      onPageChange(1); // Уведомляем о сбросе страницы
    }, delay);
  };

  // Очистка таймеров при размонтировании компонента
  useEffect(() => {
    return () => {
      if (pageChangeTimeout.current) {
        clearTimeout(pageChangeTimeout.current);
      }
      if (limitChangeTimeout.current) {
        clearTimeout(limitChangeTimeout.current);
      }
    };
  }, []);

  return total > 0 ? (
    <Stack direction="row" spacing={2} alignItems="center">
      <Pagination
        page={page}
        count={Math.ceil(total / limit)} // Вычисляем количество страниц
        onChange={handlePageChange}
      />
      <FormControl size="small">
        <Select value={limit.toString()} onChange={handleLimitChange}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  ) : null;
};

export default MyPagination;
