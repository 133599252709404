import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import { Dealer } from "../../const/dealer";
import UsersDialog from "../dialogs/UsersDialog";

type Props = {
  dealer: Dealer
}

function UsersButton({ dealer }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Users
      </Button>
      {open && 
        <UsersDialog open={open} dealer={dealer} onClose={handleClose} />
      }
    </>
  );
}

export default UsersButton;
