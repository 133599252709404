import { useForm } from "react-hook-form"
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material"
import { FormInputText } from "../form/FormInputText"
import { Dealer } from "../../const/dealer"

type Props = {
  open: boolean
  initData?: Dealer
  onClose: () => void
  onSave: (data: Dealer) => void
}

function DealerDialog({ open, initData, onClose, onSave }: Props) {
  const { control, handleSubmit } = useForm<Dealer>({
    defaultValues: initData,
    mode: 'onBlur',
  })

  const handleSave = (data: Dealer) => {
    onSave(data);
    onClose();
  }

  return open && (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{'Add Dealer'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSave)}>
          <Stack spacing={2} py={2}>
            <FormInputText
              name="name"
              control={control}
              label="Dealer name *"
              required
              errorMessage={'Please enter dealer name'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="address"
              control={control}
              label="Address *"
              required
              errorMessage={'Please enter address'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="email"
              control={control}
              label="Email"
              required
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
                maxLength: 250
              }}
            />
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              errorMessage={'Please enter phone'}
              rules={{ maxLength: 35 }}
            />
            <FormInputText
              name="site"
              control={control}
              label="Site"
              errorMessage={'Please enter site'}
              rules={{ maxLength: 500 }}
            />
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
            <Button variant="contained" color="secondary" onClick={onClose} sx={{ mr: 2 }}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Save</Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DealerDialog;
