import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useState, useRef, useEffect } from 'react';

type Props = {
  placeholder?: string;
  isLowerCase?: boolean;
  onSearch: (value: string) => void;
  delay?: number; // Задержка в миллисекундах (опционально)
};

const Search = ({ placeholder, isLowerCase, onSearch, delay = 300 }: Props) => {
  const [inputValue, setInputValue] = useState<string>('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = (value: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      onSearch(isLowerCase ? value.toLowerCase() : value);
    }, delay);

    setInputValue(value);
  };

  // Очистка таймера при размонтировании
  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel htmlFor="search">
        {placeholder || 'Search'}
      </InputLabel>
      <OutlinedInput
        id={placeholder || 'search'}
        label={placeholder || 'Search'}
        value={inputValue}
        onChange={(event) => handleInputChange(event.target.value)}
      />
    </FormControl>
  );
};

export default Search;
