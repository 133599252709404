import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Box, Button, Divider, Link, ListItemAvatar, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { ComponentProps } from 'react';
import cfg from '../../config';

type Props = ComponentProps<typeof Menu> & {
  user: any;
};

function ProfileMenu({ user, ...props }: Props) {
  const menuId = 'profile-menu';

  return (
    <Menu id={menuId} {...props}>
      <MenuList dense sx={{ minWidth: '300px' }}>
        <Box sx={{ px: 2, pb: 2, display: 'flex', alignItems: 'center' }}>
          <ListItemAvatar>
            <Avatar alt={user.name} src={user.photo} />
          </ListItemAvatar>
          <ListItemText primary={user.name} secondary={user.email} />
        </Box>
        <Divider />
        <MenuItem>
          <AccountBoxIcon />
          <Link
            href={cfg.profile}
            underline='none'
            width={'100%'}
            ml={2}
            color={'inherit'}
            textTransform={'uppercase'}
          >
            Profile
          </Link>
        </MenuItem>
        <MenuItem>
          <LogoutIcon />
          <Link
            href={`${cfg.passport}/logout?callbackUrl=${encodeURIComponent(cfg.siteAddress)}`}
            underline='none'
            width={'100%'}
            ml={2}
            color={'inherit'}
            textTransform={'uppercase'}
          >
            Logout
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default ProfileMenu;
