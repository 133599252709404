import cfg from '../config';
import { Dealer } from '../const/dealer';

const url = `${cfg.apiUrl}/api/v1/dealers`;

export const create = async (data: Dealer) => {
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const update = async (data: Dealer) => {
  const res = await fetch(`${url}/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};
