import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import DealerDialog from "../dialogs/DealerDialog";
import { Dealer } from "../../const/dealer";

type Props = {
  onSubmit: (data: Dealer) => void
}

function AddDealerButton({ onSubmit }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback((data: Dealer) => {
    setOpen(false);
    onSubmit(data);
  }, [onSubmit]);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Add Dealer
      </Button>
      {open && 
        <DealerDialog open={open} onClose={handleClose} onSave={handleSubmit} />
      }
    </>
  );
}

export default AddDealerButton;
