import React from 'react';
import Page from '../components/layouts/Page';
import { Typography } from '@mui/material';
import { useAuth } from '../components/providers/AuthProvider';

const Home: React.FC = () => {
  const { user } = useAuth();

  return (
    <Page>
      <Typography variant='h2' align='center' mt={30} color={'#838383'}>Welcome to UTS Dealer Microservice</Typography>
      <Typography variant='h4' align='center' mt={3} color={'#838383'}>{user?.roles.join(', ')}</Typography>
    </Page>
  );
};

export default Home;