import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from './components/providers/ThemeProvider';
import SWRProvider from './components/providers/SWRProvider';
import { AuthProvider } from './components/providers/AuthProvider';
import StartPage from './pages';
import DealersPage from './pages/Dealers';

function App() {
  return (
    <Router>
      <SWRProvider>
        <ThemeProvider>
          <AuthProvider>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/dealers" element={<DealersPage />} />
              </Routes>
          </AuthProvider>
        </ThemeProvider>
      </SWRProvider>
    </Router>
  );
}

export default App;
