import React, { useMemo } from 'react';
import { useAuth } from '../providers/AuthProvider';

export function useUserAccess(roles: string | string[]) {
  const { user } = useAuth();

  const accessValid = useMemo(() => {
    // TODO. store all in lower case instead of toLowerCase
    const _roles = (Array.isArray(roles) ? roles : roles.split(',')).map(r => r.trim().toLowerCase());

    const userRoles: undefined | string[] = user ? user.roles : undefined;
    return userRoles
      ? userRoles.some(ur => _roles.includes(ur.toLowerCase()))
      : false;
  }, [user, roles]);

  return { access: accessValid };
}

type Props = {
  children: React.ReactNode,
  roles: string | string[],
}

const UserAccess = ({ children, roles }: Props) => {
  const { access } = useUserAccess(roles);

  return access ? (
    <>
      {children}
    </>
  ) : null;
};

export default UserAccess;