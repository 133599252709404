import cfg from '../config';
import { DealerUser } from '../const/dealer-user';

const url = `${cfg.apiUrl}/api/v1/dealers`;

export const add = async (data: DealerUser) => {
  const res = await fetch(`${url}/${data.dealerId}/users`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId: data.userId }),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const remove = async (data: DealerUser) => {
  const res = await fetch(`${url}/${data.dealerId}/users/${data.userId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};
