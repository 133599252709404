import useSWR from "swr";
import Page from "../components/layouts/Page";
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCallback } from "react";
import AddDealerButton from "../components/containers/AddDealerButton";
import { Dealer } from "../const/dealer";
import * as dealerApi from "../api/dealer";
import UsersButton from "../components/containers/UsersButton";
import EditDealerButton from "../components/containers/EditDealerButton";

const tableHeaders = ['Name', 'Address', 'Email', 'Phone', 'Site', 'Actions'];

function DealersPage() {
  const { data: dealers = [], mutate } = useSWR('/api/v1/dealers');

  const handleAddDealer = useCallback((data: Dealer) => {
    try {
      const res = dealerApi.create(data);
      mutate(() => [...dealers, res]);
    } catch (error) {
      console.error(error);
    }
  }, [dealers, mutate]);

  const handleEditDealer = useCallback((data: Dealer) => {
    try {
      const res = dealerApi.update(data);
      mutate(() => [...dealers, res]);
    } catch (error) {
      console.error(error);
    }
  }, [dealers, mutate]);

  return (
    <Page title='Dealers'>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
        <AddDealerButton onSubmit={handleAddDealer} />
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((th) => (
                  <TableCell key={th}>{th}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dealers.map((dealer: Dealer) => (
                <TableRow
                  key={dealer.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{dealer.name}</TableCell>
                  <TableCell>{dealer.address}</TableCell>
                  <TableCell>{dealer.email}</TableCell>
                  <TableCell>{dealer.phone}</TableCell>
                  <TableCell>{dealer.site}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <UsersButton dealer={dealer} />
                      <EditDealerButton dealer={dealer} onSubmit={handleEditDealer} />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
}

export default DealersPage;